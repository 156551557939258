@import '../../styles/colors.scss';

.home {
  max-width: 1200px;
  background-color: $cl-white;
  border-radius: 24px;
  color: $cl-dark-grey;
  padding: 24px;
  margin: 0 auto;
}

.faqWrapper {
  text-align: left;
}

.faqWrapper,
.timetable,
.contact {
  margin-top: 48px;
}

.faqImage {
  display: flex;
  justify-content: center;
}

.sectionTitle {
  text-align: center;
}

.contact {
  display: flex;
  flex-direction: column;


  iframe {
    margin: 16px auto 0;
    width: 600px;
    max-width: 600px;
    height: 450px;
  }
}

.linkWrapper {
  a, a:link, a:visited {
    color: $cl-blue;
    text-decoration: none; 
  }
  a:hover {
    opacity: 0.65;
    text-decoration: none; 
  }
}

.social {
  font-size: 36px;
  margin-right: 16px;
}


@media screen and (max-width: 767px) {
  .faqImage {
    margin: 0 auto;
    width: 10%;
  }  

  .contact {
    iframe {
      max-width: 100%;
    }
  }
}

