@import './styles/colors.scss';


.main {
  background: linear-gradient(to top, $cl-white 0%, $cl-light-grey 100%);
  box-sizing: border-box;
  text-align: center;
  height: 100%;
  width: 100%;
  position: absolute;
}

.mainContent {
  width: 100%;
  height: calc(100% - 280px);
  position: absolute;
  top: 224px;
  left: 0;
  overflow: auto;
  padding-bottom: 64px;
}

@media screen and (max-width: 767px) {
  .mainContent {
    height: calc(100% - 48px);
    top: 56px;
  }
}


