// NEW COLOR SCHEMA
$cl-white: #fff;
$cl-black: #000;

$cl-light-grey: #e8e8e8;

$cl-medium-grey: #8c8c8c;

$cl-dark-grey: #191919;

$cl-red: #A30000;

$cl-blue: #4070ff;

$cl-highlight: #92a9a9;

$cl-orange: #FFA600;
$cl-gold: #ffcf40;
$cl-gold-light: #fff5d8;

$cl-gold-live-casino: #d9af5b;

