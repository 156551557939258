@import '../../styles/colors.scss';

.footer {
  background-color: $cl-gold-light;
  height: 80px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
  padding: 8px 16px;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  opacity: 1;
}

.image {
  background-color: $cl-white;
  margin-right: 16px;
  opacity: 1;
}

.iconWrapper {
  font-size: 36px;
  margin-right: 16px;
  line-height: 64px;
}

.content {
  a, a:link, a:visited {
    color: $cl-dark-grey;
    text-decoration: none; 
  }
  
  a:hover {
    opacity: 0.65;
    text-decoration: none; 
  }
}

@media screen and (max-width: 767px) {
  .footer {
    height: 40px;
    padding: 4px 8px;
  }
  
  .image {
    background-color: $cl-white;
    margin-right: 16px;
    opacity: 1;
  }
  
  .iconWrapper {
    font-size: 18px;
    margin-right: 16px;
    line-height: 36px;
  }
}
