@import '../../styles/colors.scss';

.main {
  // background-color: $cl-red;
  display: flex;
  flex-direction: row;
  height: 160px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.crestContainer {
  background-color: $cl-white;
  min-width: 200px;
  width: 200px;
  height: 200px;
  padding: 20px;
  z-index: 100;
  position: fixed;

  > img {
    width: 100%;
  }
}


.header {
  background: linear-gradient(to bottom, #A30000 0%, #e8e8e8 175%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 200px);
  padding: 24px;
  margin-left: 200px;
  opacity: .92;
}

.title {
  background-image: none;
  color: $cl-white;
  font-family: "Stalinist";
  font-size: 30px;
  text-align: left;
  text-transform: uppercase;

  &:hover {
    opacity: .85;
  }
}

.menu {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  width: 100%;
  height: 40px;
  justify-content: flex-start;
}

.menuItem {
  background-color: $cl-gold;
  border-radius: 12px;
  margin-top: auto;
  margin-right: 4px;
  padding: 6px 4px;
  text-align: center;
  text-decoration: none;
  height: 32px;
  width: 96px;

  &:hover {
    opacity: .85;
  }
  &:visited {
    color: $cl-black;
  }

  &:last-child {
    border-right: none;
  }
}

.menuItem {
  font-size: 14px;
  color: $cl-dark-grey;
  text-shadow: none;
  max-width: 88px;
  text-decoration-line: none;
}

@media screen and (max-width: 767px) {
  .main {
    height: 48px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
  }
  
  .crestContainer {
    width: 48px;
    min-width: 48px;
    height: 48px;
    padding: 4px;
    > img {
      width: 100%;
    }
  }
  
  
  .header {
    width: calc(100% - 48px);
    padding: 8px;
    margin-left: 48px;
  }
  
  .title {
    font-size: 14px;
  }
  
  .menu {
    margin-top: 4px;
    height: 16px;
  }
  
  .menuItem {
    border-radius: 6px;
    margin-right: 2px;
    padding: 0;
    padding-top: 1px;
    height: 16px;
    // width: 96px;
  
    &:hover {
      opacity: .85;
    }
    &:visited {
      color: $cl-black;
    }
  
    &:last-child {
      border-right: none;
    }
  }
  
  .menuItem {
    font-size: 12px;
    font-weight: 600;
  }
}